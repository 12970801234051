<template>
  <!-- <div class="header" :style="backgroudstyle">
    <div class="headerContent">
      <router-link :to="{ name: 'Index' }">
        <div class="leftContent">
          <img
            v-if="activeIndex == 0"
            src="~assets/img/Header/LOGO@2x.png"
            alt=""
          />
          <img
            v-if="activeIndex == 1"
            src="~assets/img/Header/LOGOBlue.png"
            alt=""
          />
          <div class="dtsumName" :style="backgroudstyle">三象工业互联网</div>
        </div></router-link
      >
      <div :class="activeIndex == 0 ? 'RightContent0' : 'RightContent1'">
        <el-menu class="el-menu-demo" mode="horizontal" text-color="#fff">
          <el-menu-item index="6">
            <div @click="toDtsum">低代码开发平台</div></el-menu-item
          >
          <el-submenu index="1">
            <template slot="title" class="title">行业工业互联网平台</template>

            <el-menu-item index="1-1"
              ><a href="https://fzxd.dtsum.com/#/index"
                >纺织行业工业互联网平台</a
              >
            </el-menu-item>

            <el-menu-item index="1-2">
              <a href="http://jgj.dtsum.com/#/">紧固件行业工业互联网平台</a>
            </el-menu-item>
            <el-menu-item index="1-3"
              ><a href=" http://qp.dtsum.com/#/"> 岱山工业互联网平台</a>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">解决方案</template>
            <el-menu-item index="2-1"
              ><router-link :to="{ name: 'SolutionTwo' }"
                >纺织行业数字化工厂解决方案</router-link
              ></el-menu-item
            >
            <el-menu-item index="2-2"
              ><router-link :to="{ name: 'SolutionOne' }"
                >紧固件行业数字化工厂解决方案</router-link
              ></el-menu-item
            >
            <el-menu-item index="2-3"
              ><router-link :to="{ name: 'SolutionThree' }"
                >汽船配行业数字化工厂解决方案</router-link
              ></el-menu-item
            >
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">开发者平台</template>

            <el-menu-item index="3-1"
              ><router-link :to="{ name: 'LowCodePlatform' }">
                三象低代码平台
              </router-link></el-menu-item
            >

            <el-menu-item index="3-2">
              <router-link :to="{ name: 'IOTPlatform' }"
                >三象IOT平台</router-link
              ></el-menu-item
            >
            <el-menu-item index="3-3">
              <router-link :to="{ name: 'BIPlatform' }">
                三象BI平台</router-link
              ></el-menu-item
            >
          </el-submenu>
          <el-submenu index="4">
            <template slot="title" class="title">案例精选</template>
            <el-menu-item index="4-1"
              ><router-link :to="{ name: 'CustomerAOYA' }"
                >纺织行业案例
              </router-link></el-menu-item
            >
            <el-menu-item index="4-2"
              ><router-link :to="{ name: 'CustomerQljgj' }"
                >紧固件行业案例
              </router-link></el-menu-item
            >
            <el-menu-item index="4-3"
              ><router-link :to="{ name: 'CustomerDZMotor' }"
                >电机行业案例
              </router-link></el-menu-item
            >
            <el-menu-item index="4-4"
              ><router-link :to="{ name: 'CustomerJXSponge' }"
                >汽配行业案例
              </router-link></el-menu-item
            >
          </el-submenu>

          <el-submenu index="5">
            <template slot="title" class="title">产品演示</template>
            <el-menu-item index="5-1">
              <a href="http://jf.dtsum.com">家纺行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-2">
              <a href="http://jgjys.dtsum.com">紧固件行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-3">
              <a href="http://jyx.dtsum.com">金银线行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-4">
              <a href="http://zx.dtsum.com">制线行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-5">
              <a href="http://dj.dtsum.com">电机行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-6">
              <a href="http://qp.dtsum.com">汽配行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-7">
              <a href="http://zs.dtsum.com">注塑行业数字化管控系统</a>
            </el-menu-item>
            <el-menu-item index="5-8">
              <a href="http://testzt.dtsum.com">线带纺织行业数字化管控系统</a>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="6" class="AboutUs">
            <router-link :to="{ name: 'AboutUs' }"
              >关于我们</router-link
            ></el-menu-item
          >
        </el-menu>
      </div>
    </div>
  </div> -->
  <div>
    <div class="header" style="z-index: 90;">
      <div class="leftContent">
        <img src="~assets/img/Header/LOGOBlue.png" alt="" />
        <div class="dtsumName">
          <router-link :to="{ name: 'Index' }">三象工业互联网</router-link>
        </div>
      </div>
      <div class="rightContent">
        <img src="~assets/img/Header/open.png" alt="" @click="drawer = true" />
      </div>
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      :with-header="false"
      size="1000px"
    >
      <div class="drawermain">
        <div class="close">
          <img
            src="~assets/img/Header/close.png"
            alt=""
            @click="drawer = false"
          />
        </div>
        <div class="drawertop" style="padding-top: 2%;height: 42%;">
          <div class="drawerline" style="width: 26%;">
            <div class="title1">行业工业互联网平台</div>
            <div class="title2">
              <a href="https://fzxd.dtsum.com/#/index" target="_blank"
                >纺织行业工业互联网平台</a
              >
            </div>
            <div class="title2">
              <a href="http://jgj.dtsum.com/#/" target="_blank"
                >紧固件行业工业互联网平台</a
              >
            </div>
            <div class="title2">
              <a href=" http://fzxd.dtsum.com" target="_blank">
                岱山工业互联网平台</a
              >
            </div>
          </div>
          <div class="drawerline">
            <div class="title1">解决方案</div>
            <div class="title2">
              <router-link :to="{ name: 'SolutionTwo' }"
                >纺织行业数字化工厂</router-link
              >
            </div>
            <div class="title2">
              <router-link :to="{ name: 'SolutionOne' }"
                >紧固件行业数字化工厂</router-link
              >
            </div>
            <div class="title2">
              <router-link :to="{ name: 'SolutionThree' }"
                >汽船配行业数字化工厂</router-link
              >
            </div>
          </div>
          <div class="drawerline">
            <div class="title1">案例精选</div>
            <div class="title2">
              <router-link :to="{ name: 'CustomerAOYA' }"
                >纺织行业案例
              </router-link>
            </div>
            <div class="title2">
              <router-link :to="{ name: 'CustomerQljgj' }"
                >紧固件行业案例
              </router-link>
            </div>
            <div class="title2">
              <router-link :to="{ name: 'CustomerDZMotor' }"
                >船舶行业案例
              </router-link>
            </div>
            <div class="title2">
              <router-link :to="{ name: 'CustomerJXSponge' }"
                >汽配行业案例
              </router-link>
            </div>
          </div>
          <div class="drawerline">
            <div class="title1">开发者平台</div>
            <div class="title2">
              <router-link :to="{ name: 'LowCodePlatform' }">
                三象低代码平台
              </router-link>
            </div>
            <div class="title2">
              <router-link :to="{ name: 'IOTPlatform' }"
                >三象IOT平台</router-link
              >
            </div>
            <div class="title2">
              <router-link :to="{ name: 'BIPlatform' }">
                三象BI平台</router-link
              >
            </div>
          </div>
        </div>
        <div class="middletitle">产品演示</div>
        <div class="drawertop" style="padding-top: 1%;height: 40%;">
          <div class="drawerline2">
            <div class="title1">紧固件行业</div>
            <div class="title2">
              <a href="http://jgjys.dtsum.com" target="_blank">紧固件生产ERP</a>
            </div>
            <!-- <div class="title2">紧固件生产MES</div> -->
            <el-tooltip effect="light" content="敬请期待" placement="top-start">
              <div class="title2">紧固件生产MES</div>
            </el-tooltip>
          </div>
          <div class="drawerline2">
            <div class="title1">纺织行业</div>
            <div class="title2">
              <a href="http://yiwuniao.dtsum.com" target="_blank"
                >印花生产ERP</a
              >
            </div>
            <div class="title2">
              <a href="http://lingxian.dtsum.com" target="_blank"
                >丝带生产ERP</a
              >
            </div>
            <div class="title2">
              <a href="http://jyx.dtsum.com" target="_blank">金银线生产ERP</a>
            </div>
            <div class="title2">
              <a href=" http://jf.dtsum.com" target="_blank">家纺生产ERP</a>
            </div>
            <div class="title2">
              <a href=" http://testzt.dtsum.com" target="_blank">织带生产MES</a>
            </div>
            <div class="title2">
              <a href=" http://zx.dtsum.com" target="_blank">制线行业ERP</a>
            </div>
          </div>
          <div class="drawerline2">
            <div class="title1">船舶行业</div>
            <el-tooltip effect="light" content="敬请期待" placement="top-start">
              <div class="title2">PAMS系统</div>
            </el-tooltip>
            <el-tooltip effect="light" content="敬请期待" placement="top-start">
              <div class="title2">PRMS系统</div>
            </el-tooltip>
            <div class="title2">
              <a href="http://jiesheng.dtsum.com" target="_blank"> 修造船ERP</a>
            </div>
            <el-tooltip effect="light" content="敬请期待" placement="top-start">
              <div class="title2">BI系统</div>
            </el-tooltip>
            <el-tooltip effect="light" content="敬请期待" placement="top-start">
              <div class="title2">智能安防</div>
            </el-tooltip>
            <el-tooltip effect="light" content="敬请期待" placement="top-start">
              <div class="title2">机具管理</div>
            </el-tooltip>
          </div>
          <div class="drawerline2">
            <div class="title1">汽车制造业</div>
            <div class="title2">
              <a href="http://jjt.dtsum.com" target="_blank">汽配生产ERP</a>
            </div>
            <div class="title2">
              <a href="http://jjtgkj.dtsum.com" target="_blank">汽配生产MES</a>
            </div>
          </div>
          <div class="drawerline2">
            <div class="title1">其他</div>
            <el-tooltip effect="light" content="敬请期待" placement="top-start">
              <div class="title2">设备管理系统</div>
            </el-tooltip>
            <el-tooltip effect="light" content="敬请期待" placement="top-start">
              <div class="title2">BI报表系统</div>
            </el-tooltip>
            <div class="title2">
              <a href="http://ems.dtsum.com" target="_blank">能碳管理</a>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      activeIndex: 0,
      drawer: false
    };
  },
  methods: {
    toDtsum() {
      window.open("http://siku.dtsum.com", "_self");
    }
  },
  computed: {
    backgroudstyle() {
      return this.activeIndex === 1
        ? { background: "white", color: "#1e90ff" }
        : {};
    }
  },
  created() {
    if (this.$route.path === "/Index") {
      this.activeIndex = 0;
    } else {
      this.activeIndex = 1;
    }
  }
};
</script>
<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 64px;
  background: url("~assets/img/Header/headerpic.png") no-repeat;
  background-size: 100% 100%;
}
.leftContent {
  width: 345px;
  height: 64px;
  line-height: 64px;
  display: flex;
  justify-content: space-between;
  img {
    width: 114px;
    height: 23px;
    margin-top: 20px;
    margin-left: 10px;
    vertical-align: text-bottom;
  }
  .dtsumName {
    vertical-align: middle;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    letter-spacing: 3px;
    color: rgba(51, 51, 51, 0.65);
  }
}
.rightContent {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 5px;
    width: 40px;
    height: 40px;
    vertical-align: text-bottom;
  }
}
.drawermain {
  width: 100%;
  height: 100%;
  background: url("~assets/img/newindex/drawer.png") no-repeat;
  background-size: 100% 100%;
  margin: 0 auto;
}
.close {
  display: flex;
  justify-content: end;
  img {
    width: 40px;
    height: 40px;
    vertical-align: text-bottom;
  }
}
.drawertop {
  width: 92%;
  margin-left: 4%;
  display: flex;
  justify-content: space-around;
}
.drawerline {
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.drawerline2 {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}
.title1 {
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: bold;
  font-size: 17px;
  color: #1878ff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  line-height: 36px;
  padding-left: 10px;
}
.title2 {
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: 500;
  font-size: 17px;
  color: #bfbfbf;
  text-align: left;
  font-style: normal;
  text-transform: none;
  line-height: 36px;
  padding-left: 10px;
}
.middletitle {
  font-family: OPlusSans 3, OPlusSans 30;
  font-weight: bold;
  font-size: 18px;
  color: #006aff;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-left: 5%;
  margin-top: 1%;
}
.title2 a {
  color: black; /* 带链接的文字为黑色 */
  text-decoration: none; /* 移除默认的下划线 */
}
</style>
<!-- <style lang="less" scoped>
.header {
  width: 100%;
  .headerContent {
    width: 1420px;
    height: 64px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .leftContent {
      width: 315px;
      height: 64px;
      line-height: 64px;
      display: flex;
      justify-content: space-between;
      img {
        width: 114px;
        height: 23px;
        margin-top: 22px;
        vertical-align: text-bottom;
      }
      .dtsumName {
        vertical-align: middle;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 3px;
      }
    }
  }
}
</style>
<style lang="less">
.RightContent0 {
  .el-menu {
    padding: 0;
    background-color: transparent !important;
    border: none !important;
  }
  /* //二次菜单悬浮及背景样式 */
  .el-menu--popup-bottom-start .el-menu-item:hover {
    color: #3d7eff !important;
    background-color: #fff !important;
  }
  .el-menu--popup-bottom-start .el-menu-item {
    background: #fff !important;
    color: #7a8e9d !important;
  }

  /* //鼠标悬浮时，子菜单的样式 应用于只有一级的菜单*/
  .el-menu-item:hover {
    outline: 0 !important;
    color: #fff !important;
    background: none !important;
    border: none !important;
  }
  .el-menu-item {
    border: none !important;
    // padding: 0 !important;
  }
  .AboutUs {
    padding: 0 !important;
  }

  /* //鼠标悬浮时，主菜单的样式 应用于多级菜单的主菜单*/
  .el-submenu__title:focus,
  .el-submenu__title:hover {
    outline: 0 !important;
    color: #fff !important;
    background: none !important;
    border: none !important;
  }
  .el-submenu__title {
    color: #fff !important;
    border: none !important;
  }
  .el-icon-arrow-down:before {
    color: #fff;
  }
  .router-link-active {
    text-decoration: none;
  }
}
.RightContent1 {
  .el-menu {
    background-color: transparent !important;
    border: none !important;
    color: #1e90ff !important;
  }
  /* //二次菜单悬浮及背景样式 */
  .el-menu--popup-bottom-start .el-menu-item:hover {
    color: #3d7eff !important;
    background-color: #fff !important;
  }
  .el-menu--popup-bottom-start .el-menu-item {
    background: #fff !important;
    color: #7a8e9d !important;
  }

  /* //鼠标悬浮时，子菜单的样式 应用于只有一级的菜单*/
  .el-menu-item:hover {
    outline: 0 !important;
    color: #1e90ff !important;
    border: none !important;
  }
  .el-menu-item {
    border: none !important;
    color: #1e90ff !important;
    // padding: 0 !important;
  }
  .AboutUs {
    padding: 0 !important;
  }
  .el-submenu__title {
    color: #1e90ff !important;
    border: none !important;
  }

  /* //鼠标悬浮时，主菜单的样式 应用于多级菜单的主菜单*/
  .el-submenu__title:focus,
  .el-submenu__title:hover {
    outline: 0 !important;
    color: #1e90ff !important;
    background: none !important;
    border: none !important;
  }
  .el-icon-arrow-down:before {
    color: #1e90ff;
  }
  .router-link-active {
    text-decoration: none;
  }
}
</style> -->
